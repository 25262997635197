.gallery-cell{
    width: 100%;
    img{
        width: 100%;
    }
}

.slider-column{
    position: relative;

    .flickity-viewport{
        overflow: hidden;
    }
}