.collection{
    margin: 0 auto;
    display: block;
    width: 90%;

    .block-row{
        width: 100%;
        display: flex;
        flex-flow: column nowrap;

        .block{
            flex: 1 0 100%;
            margin: 0 0 $gap * 2 0;
        }

        @media only screen and ( min-width: $tablet ){
            flex-flow: row wrap;

            .block{
                flex: 1 0 45%;
                margin: 0 $gap * 2 $gap * 2 0;
            }
        }
    }


    .block{
        background: $white;
        box-shadow: $shadow;
        padding-bottom: $gap;
        border-bottom: 6px solid $white;
        text-decoration: none;

        h2, p{
            padding-left: $gap;
            padding-right: $gap;
        }

        h2{
            margin-top: $gap;
        }

        figure{
            padding: 0;
            margin: 0;
            position: relative;
        }

        .price{
            position: absolute;
            bottom: $gap;
            right: 0;
            background: $white;
            font-size: 1.8rem;
            color: $green;
            padding: 5px 10px;
            font-family: $head-stack;
        }

        img{
            width: 100%;
            height: auto;
        }

        &:hover{
            border-color: $blue;
            h2{
                color: $blue;
            }
        }

    }
}