/**
* Main CSS file 
* this is where all your imports go.
* 
*/

//normalize
@import 'vendors/normalize';
@import 'vendors/flickity';

//Custom variables:
@import 'variables';

//Core styling:
@import 'base/mixins';
@import 'base/a11y';
@import 'base/typography';


// Basics:
@import 'components/footer';
@import 'components/general';
@import 'components/header';
@import 'components/nav';
@import 'components/button';
@import 'components/form';
@import 'components/gallery';
@import 'components/image';

// Sections:
@import 'components/section';
@import 'components/intro';
@import 'components/cta';

//Columns:
@import 'components/linkblock';
@import 'components/collection';


//Pages:
@import 'pages/arrangement';
@import 'pages/contact';