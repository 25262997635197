/**
* Footer CSS file 
* Styling for the footer:
* 
*/

.wrapper{
	min-height:100%;
	margin-bottom:-50px;
}

footer{
	height:50px;
	background:#444;
    width:100%;
    
    p{
        padding: 0;
        margin: 0 auto;
        width: 100%;
        max-width: $container;
        color: white;
    }
}

.section.link-footer{
    background: #444;
    width: 100vw;
    max-width: initial;

    p, a, h2, h3{
        color: white;
    }

    .column-wrapper{
        max-width: $container;
        width: 90%;
        margin: 0 auto;

        @media only screen and ( min-width: $desktop ){
            .column.content{
                padding-top: 75px;
            }

            .column.content:first-of-type{
                padding-top: 0;
            }
        }
    }
}