.form-column{
    .column-title{
        @include title();
        margin-bottom: $gap-large;

    }

    .field-row{
        display: flex;
        flex-flow: column nowrap;

        @media only screen and ( min-width: $tablet ){
            flex-flow: row nowrap;
        }

        .field-wrapper{
            flex: 1;
            box-sizing: border-box;
            padding-right: $gap;
            margin-bottom: $gap;
            position: relative;

            &:last-of-type{
                padding-right: 0;
            }

            label{
                box-sizing: border-box;
                display: block;
                width: 100%;
                font-size: .9rem;
                font-weight: bold;
                padding-bottom: 4px;
            }

            input{
                box-sizing: border-box;
                display: block;
                width: 100%;
                padding: 5px;
                font-size: 1.2rem;
            }

            @media only screen and (min-width: $tablet ){
                margin-bottom: $gap * 2;
            }
        }
    }

    .field{
        border: 2px solid $grey;
        color: $black;
    
        &.validated-false{
            border-color: crimson;
        }

        &.datepicker{
            background: url('../images/icons/calendar.png');
            background-position: 99% 50%;
            background-size: 1.3rem 1.3rem;
            background-repeat: no-repeat;
        }
    }

    .validation-error{
        color: crimson;
        font-size: .9rem;
        font-weight: bold;
    }

    .payment-wrapper{
        background: $green;
        padding: $gap * 2;
        display: flex; 
        flex-flow: column nowrap;
        width: calc( 100% + #{$gap * 4} );
        margin-left: $gap * -2;
        margin-right: $gap * -2;

        p, strong, a, label{
            color: $white;
        }

        select{
            width: 100%;
        }

        .price{
            display: block;
            font-size: 1.6rem;
            color: $white;
            font-weight: bold;
            margin: $gap 0 0 0;
        }
        
        .field-wrapper label{
            padding-bottom: 20px;
            display: block;
        }
        

        @media only screen and (min-width: $tablet ){
            flex-flow: row nowrap;

            .field-wrapper{
                padding-left: $gap * 2;
                margin-left: $gap * 2;
                margin-bottom: 0;
                text-align: right;
                border-left: 1px solid lighten( $green, 20% );
            }
        }
    }

    .form-footer{
        padding-top: $gap * 2;
        text-align: right;

        button{
            background: $red;
            color: $white;
            border: 0;
            font-size: 1.3rem;
            padding: 10px 20px;
            border-radius: 4px;
            box-shadow: 0 1px 1px darken( $red, 40% );
            border: 2px solid $red;

            &:hover{
                background: $blue;
                color: $black;
                box-shadow: 0 1px 1px darken( $blue, 40% );
                border: 2px solid $blue;
            }
        }
    }
}

.ui-datepicker{
    background: $white;
    z-index: 100 !important;
    width: 270px;
    padding: 0 $gap;

    table, .ui-datepicker-header{
        width: 100%;
    }

    table{
        padding: $gap;

        th, td{
            padding: 7px 0;
        }
    }

    .ui-datepicker-unselectable{
        opacity: .4;
    }

    .ui-datepicker-header{
        background: $green;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        width: calc( 100% + 30px );
        margin-left: -$gap;
        margin-right: -$gap;
        margin-bottom: $gap;

        span, a{
            color: $white;
            cursor: pointer;
        }

        .ui-datepicker-next{
            order: 3;
        }
    }
}