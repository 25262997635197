/**
* Header CSS file 
* Styling for the header:
* 
*/

header{
    width: 90%;
    max-width: $container;
    left: 50%;
    transform: translate(-50%, 0);
    position: relative;
    z-index: 200;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    
    
	.logo{
        width: 105px;
        height: auto;
		padding:0;
        text-decoration:none;
        background: $white;
        display: block;
        box-shadow: 0 2px 2px rgba(0,0,0,.3);

        img{
            width: 120px;
            margin-right: -$gap;
            height: auto;
        }

		h1{
			font-size:32px;
		}
    }
    
    nav{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 1em;
        width: 100vw;
        height: 100vh;
        background: $green;
        transform: translateX(100%);
        transition: transform, .3s ease;

        &.active{
            transform: translateX(0%);
        }

        ul{
            list-style: none;
            display: flex;
            flex-flow: column nowrap;
            margin: 0;
            padding: 0;
        }

        li{
            margin-left: $gap;
        }

        a, a:visited{
            font-family: $head-stack;
            font-size: 1.6rem;
            color: $white;
            text-shadow: 0 1px 3px rgba(0,0,0,.3);
            text-decoration: none;
            border: 2px solid transparent;
            display: block;
            

            &:focus{
                border-color: $white;
            }

            &:after{
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background: transparent;
            }
        }

        .current-menu-item a, a:hover{
            color: $white;
            &:after{
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background: $white;
            }
        }

        /*
        a:hover{
            color: $green;
            &:after{
                background: $green;
            }
        }*/

        @media only screen and( min-width: $tablet ){
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            width: auto;
            height: auto;
            background: transparent;
            transform: none;

            ul{
                flex-flow: row nowrap;
            }
        }
    }

    .menu-switch{
        position: absolute;
        top: $gap;
        right: 0;
        background: $white;
        width: 3em;
        height: 3em;
        border-radius: 4px;
        padding: 10px;
        border: 2px solid $white;
        span{
            width: 100%;
            height: 3px;
            background: $green;
            margin-bottom: 4px;
            display: block;
        }

        i{
            font-size: .5rem;
            display: block;
            text-align: center;
            color: $green;
            font-style: normal;
        }

        &:focus, &:hover{
            border-color: $black;
            span{
                background-color: $black;
            }
            i{
                color: $black;
            }
            outline: none;
        }

        @media only screen and (min-width: $tablet ){
            display: none;
        }
    }
}