.arrangement{
    .section:not(.intro){
        background: $white;
        position: relative;

        @media only screen and (min-width: $tablet){
            width: 80%;
        }

        @media only screen and ( min-width: $desktop ){
            width: 60%;
        }

        .column-wrapper{
            box-sizing: border-box;
            background: $white;
            padding: $gap * 2 $gap * 2 $gap $gap * 2;
            box-shadow: $shadow;

            .column.content h2{
                margin-top: 0;
                @include title();
                line-height: 180%;
            }

            &.half-half .column.content{
                position: relative;

                .button{
                    margin-top: $gap;
                    margin-bottom: 10px;
                    float: right;
                }
            }
        }

        &:after{
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            height: 10px;
            background: $white;
            display: block;
            content: '';
            z-index: 11;
        }

        &:last-of-type:after{
            display: none;
        }


        .column.content{
            font-size: 1.2rem;
            line-height: 170%;
            margin-bottom: 0;
            p{
                margin: 0;
            }
        }
    }

}