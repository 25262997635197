.section.intro{
    position: relative;
    width: 100vw;
    max-width: 100vw;
    margin: -110px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $gap-large * 2.5 0;


    .backdrop{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url( '../images/backdrop.jpg' );
        background-position: center center;
        background-size: cover;
        
        &:after, &:before{
            content: '';
            position: absolute;
            bottom: 0;
            height: 20vh;
            left: 0;
            right: 0;
            background: linear-gradient( transparent, rgba( $green, .5 ) );
        }

        &:before{
            bottom: auto;
            top: 0;
            background: linear-gradient( darken( $green, 20% ), transparent  );
            mix-blend-mode: darken;
        }
    }

    .column{
        z-index: 2;
        text-align: center;
        .column-title, p{
            color: $white;
            text-shadow: 0 1px 5px rgba( 0,0,0,.3 );
        }
        .column-title{
            font-size: 3rem;
        }

        p{
            font-size: 1.6rem;
            max-width: 75%;
            line-height: 160%;
            margin: $gap auto $gap-large auto;
        }

        .button{
            padding: 13px 43px 13px 28px;
            font-size: 1.3rem;
            color: $white;
            &:after{
                top: .7em;
            }
        }
    }

    + .section{
        margin-top: -$gap-large;

        @media only screen and ( min-width: $desktop ){
            margin-top: -( $gap-large * 2 );
        }
    }
}



.detail.home-page .section.intro{
    @media only screen and ( min-width: $desktop ){ 
        min-height: 65vh;
    }
    
    .backdrop{
        &:before{
            background: linear-gradient( rgba( $red, .5 ), transparent );
        }
        &:after{
            background: linear-gradient( transparent, rgba( $red, .5 ) );
        }
    }
}