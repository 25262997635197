.detail.contact, .detail.thanks{
    .section:not(.intro){
        background: $white;
        position: relative;
        padding: $gap * 2;
        box-shadow: $shadow;
    }

    .column-title{
        @include title();
        line-height: 180%;
        margin-top: 0;
    }    

    .column.content p{
        text-align: center;
    }

    .field{
        padding: 7px $gap;
    }

    textarea.field{
        width: 100%;
        min-height: 200px;
        padding: $gap;
    }

}

.detail.thanks{
    min-height: calc( 100vh - 45px );
}