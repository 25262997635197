.section.cta{
    background: $grey;
    width: 100vw; 
    max-width: initial;
    padding: $gap-large * 3 0 $gap-large 0;
    margin-top: -75px;

    .column-wrapper .column{
        background: none;
        box-shadow: none;
        max-width: $container;
        width: 100%;
        margin: 0 auto;
        background: $blue;
        padding-left: $gap * 2;
        padding-right: $gap * 2;
        p  {
            text-align: center;
            font-size: 1.4rem;
            line-height: 160%;

            @media only screen and ( min-width: $desktop ){
                padding-left: $gap-large * 2;
                padding-right: $gap-large * 2;
            }
        }
    }
}