/**
* Variable file 
* place global variables here:
* 
*/


$font-stack: Helvetica, Arial, sans-serif;
$head-stack:'Crete Round', Georgia;

$black:#444;
$white:#fff;
$grey:#f8f8f8;

$green:#4EB69F;
$blue:#75CED1;
$red:#D17585;

$font-color: $black;

$container: 1100px;
$desktop: 1024px;
$tablet: 768px;
$phone: 320px;

$gap: 15px;
$gap-large: 50px;

$shadow: 0 0 4px rgba(0,0,0,.1);