
/* Increase frame-rate when scrolling by not registering pointer-events:*/
.disable-hover, .disable-hover * { pointer-events: none !important; }

/* Screenreader text support: */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:focus{
		background-color: $white;
		border-radius: 3px;
        clip: auto !important;
        color: $black;
		display: block;
		font-size: rem( 14px );
		height: auto;
		left: 5px;
		padding: 15px 23px 14px;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}