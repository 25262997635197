/**
* General CSS file 
* Styling for all general classes:
* 
*/

html, body{
	margin:0;
	padding:0;
	width:100%;
    height:100%;
    overflow: scroll;
    overflow-x: hidden;
}

body{
	background:#fff;
	font-size:16px;
	color:$font-color;
	font-family:$font-stack;
}

/**
 * firefox hack for flexbox
 */
*{
	min-height:0;
	min-width:0;
}


/**
 * General Classes:
 */

	/* Increase frame-rate when scrolling by not registering pointer-events:*/
	.disable-hover, .disable-hover * { pointer-events: none !important; }

	/* Mustache link: */
	a[class^="mustache"]{display:block;;cursor:pointer;text-transform:uppercase;float:none;position:absolute;background:url('../images/mustache.png');width:80px;height:15px;left:50%;margin-left:-40px;top:5px;opacity:1;}
	a[class^="mustache"]:hover{opacity:.5;}
	a.mustache--black{background-position:0 20px;}

	/* Screenreader text support: */
	.screen-reader-text {
		clip: rect(1px, 1px, 1px, 1px);
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
	
		&:focus{
			background-color: #f1f1f1;
			border-radius: 3px;
			clip: auto !important;
			color: #21759b;
			display: block;
			font-size: rem( 14px );
			height: auto;
			left: 5px;
			padding: 15px 23px 14px;
			top: 5px;
			width: auto;
			z-index: 100000; /* Above WP toolbar. */
		}
	}