.block-column{
    background: $white;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: $shadow;
    padding-bottom: $gap;

    img{
        width: 100%;
        height: auto;
    }
    
    .column-title{
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        &:after{
            margin-top: 10px;
            content: '';
            width: 50px;
            height: 10px;
            background: $red;
            display: block;
        }
    }
    
    p{
        padding: 0 $gap $gap $gap;
    }

    .button{
        color: $white;
        margin: auto auto $gap auto;
    }

    &:nth-of-type(2) {
        .column-title:after{
            background: $blue;
        }
        .button{
            background: $blue;
            border-color: $blue;
            box-shadow: 0 1px 1px darken( $blue, 40% );
            color: darken( $blue, 40% );

            &:after{
                background-image: url('../images/icons/icon-right.svg')
            }

            &:hover{
                background: darken( $blue, 20% );
                border-color: darken( $blue, 20% );
            }
        }
    }

    &:nth-of-type(3){
        .column-title:after{
            background: $green;
        }
        .button{
            
            background: $green;
            border-color: $green;
            box-shadow: 0 1px 1px darken( $green, 40% );

            &:hover{
                background: darken( $green, 20% );
                border-color: darken( $green, 20% );
            }
        }
    }
}