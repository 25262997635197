/**
* UI CSS file 
* Everything that enables user-interaction gets bootstrapped here
* 
*/

.button{
    padding: 13px 33px 13px 18px;
	background:$red;
	position: relative;
    display: inline-block;
    
	color:#fff;
	text-decoration:none;
    line-height:100%;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: 0 1px 1px darken( $red, 40% );
    border: 2px solid $red;

    $easing : ease-out;
    transition: box-shadow .1s $easing, transform .1s $easing, background-color .1s $easing;
    
    &:after{
        transition: transform, .15s $easing;
        position: absolute;
		right: 1.3em;
		top: .8em;
        width: 1em;
        height: 1em;
        line-height: 100%;
        content: '';
        background: url('../images/icons/icon-right-white.svg');
        display: inline-block;
        background-size: cover;
        margin: 0 -10px -3px 7px;
    }

    &:hover{
        background: darken( $red, 30% );
        border-color: darken( $red, 30% );
        &:after{
            transform: translateX(.25em);
        }
    }

    &:focus {
		border: 2px solid $black;
		outline: none;
	}
}


.btn, a[class^="btn--"]{
	@extend .button;
}


a, a:visited{
	color:#323232;
	text-decoration:underline;
}
