/**
* Main CSS file 
* this is where all your imports go.
* 
*/
/**
* normalize.css v3.0.1
* git.io/normalize
* 
*/
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*! Flickity v1.1.0
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1; }

/**
* Variable file 
* place global variables here:
* 
*/
/**
* Mixin file 
* place global mixins here:
* 
*/
/* Boxsizing shortcut: */
/* Retina images: */
/* "Hard" gradients: */
/* REM font sizes: */
/* Increase frame-rate when scrolling by not registering pointer-events:*/
.disable-hover, .disable-hover * {
  pointer-events: none !important; }

/* Screenreader text support: */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #fff;
    border-radius: 3px;
    clip: auto !important;
    color: #444;
    display: block;
    font-size: rem(14px);
    height: auto;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/**
* All base typography 
* 
*/
h1, h2, h3, h4, h5, h6 {
  color: #444;
  font-family: "Crete Round", Georgia; }

p, a, span, ol, ul, li, strong, em, i, b, big, small, tt, abbr, acronym,
cite, code, dfn, em, kbd, samp, var, br, sub, sup, button, input, label,
select, textarea {
  color: #444;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1px; }

* {
  font-display: swap;
  box-sizing: border-box; }

h1 {
  font-size: 44px; }

h2 {
  font-size: 38px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 14px; }

/**
* Footer CSS file 
* Styling for the footer:
* 
*/
.wrapper {
  min-height: 100%;
  margin-bottom: -50px; }

footer {
  height: 50px;
  background: #444;
  width: 100%; }
  footer p {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    color: white; }

.section.link-footer {
  background: #444;
  width: 100vw;
  max-width: initial; }
  .section.link-footer p, .section.link-footer a, .section.link-footer h2, .section.link-footer h3 {
    color: white; }
  .section.link-footer .column-wrapper {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto; }
    @media only screen and (min-width: 1024px) {
      .section.link-footer .column-wrapper .column.content {
        padding-top: 75px; }
      .section.link-footer .column-wrapper .column.content:first-of-type {
        padding-top: 0; } }

/**
* General CSS file 
* Styling for all general classes:
* 
*/
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden; }

body {
  background: #fff;
  font-size: 16px;
  color: #444;
  font-family: Helvetica, Arial, sans-serif; }

/**
 * firefox hack for flexbox
 */
* {
  min-height: 0;
  min-width: 0; }

/**
 * General Classes:
 */
/* Increase frame-rate when scrolling by not registering pointer-events:*/
.disable-hover, .disable-hover * {
  pointer-events: none !important; }

/* Mustache link: */
a[class^="mustache"] {
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  float: none;
  position: absolute;
  background: url("../images/mustache.png");
  width: 80px;
  height: 15px;
  left: 50%;
  margin-left: -40px;
  top: 5px;
  opacity: 1; }

a[class^="mustache"]:hover {
  opacity: .5; }

a.mustache--black {
  background-position: 0 20px; }

/* Screenreader text support: */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: rem(14px);
    height: auto;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/**
* Header CSS file 
* Styling for the header:
* 
*/
header {
  width: 90%;
  max-width: 1100px;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 200;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between; }
  header .logo {
    width: 105px;
    height: auto;
    padding: 0;
    text-decoration: none;
    background: #fff;
    display: block;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3); }
    header .logo img {
      width: 120px;
      margin-right: -15px;
      height: auto; }
    header .logo h1 {
      font-size: 32px; }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 1em;
    width: 100vw;
    height: 100vh;
    background: #4EB69F;
    transform: translateX(100%);
    transition: transform, .3s ease;
    /*
        a:hover{
            color: $green;
            &:after{
                background: $green;
            }
        }*/ }
    header nav.active {
      transform: translateX(0%); }
    header nav ul {
      list-style: none;
      display: flex;
      flex-flow: column nowrap;
      margin: 0;
      padding: 0; }
    header nav li {
      margin-left: 15px; }
    header nav a, header nav a:visited {
      font-family: "Crete Round", Georgia;
      font-size: 1.6rem;
      color: #fff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      text-decoration: none;
      border: 2px solid transparent;
      display: block; }
      header nav a:focus, header nav a:visited:focus {
        border-color: #fff; }
      header nav a:after, header nav a:visited:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: transparent; }
    header nav .current-menu-item a, header nav a:hover {
      color: #fff; }
      header nav .current-menu-item a:after, header nav a:hover:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: #fff; }
    @media only screen and (min-width: 768px) {
      header nav {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: auto;
        height: auto;
        background: transparent;
        transform: none; }
        header nav ul {
          flex-flow: row nowrap; } }
  header .menu-switch {
    position: absolute;
    top: 15px;
    right: 0;
    background: #fff;
    width: 3em;
    height: 3em;
    border-radius: 4px;
    padding: 10px;
    border: 2px solid #fff; }
    header .menu-switch span {
      width: 100%;
      height: 3px;
      background: #4EB69F;
      margin-bottom: 4px;
      display: block; }
    header .menu-switch i {
      font-size: .5rem;
      display: block;
      text-align: center;
      color: #4EB69F;
      font-style: normal; }
    header .menu-switch:focus, header .menu-switch:hover {
      border-color: #444;
      outline: none; }
      header .menu-switch:focus span, header .menu-switch:hover span {
        background-color: #444; }
      header .menu-switch:focus i, header .menu-switch:hover i {
        color: #444; }
    @media only screen and (min-width: 768px) {
      header .menu-switch {
        display: none; } }

/**
* nav CSS file 
* Styling for the header:
* 
*/
/**
* UI CSS file 
* Everything that enables user-interaction gets bootstrapped here
* 
*/
.button, .btn, a[class^="btn--"] {
  padding: 13px 33px 13px 18px;
  background: #D17585;
  position: relative;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  line-height: 100%;
  text-shadow: none;
  border-radius: 3px;
  box-shadow: 0 1px 1px #5c1f29;
  border: 2px solid #D17585;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background-color 0.1s ease-out; }
  .button:after, .btn:after, a[class^="btn--"]:after {
    transition: transform, 0.15s ease-out;
    position: absolute;
    right: 1.3em;
    top: .8em;
    width: 1em;
    height: 1em;
    line-height: 100%;
    content: '';
    background: url("../images/icons/icon-right-white.svg");
    display: inline-block;
    background-size: cover;
    margin: 0 -10px -3px 7px; }
  .button:hover, .btn:hover, a[class^="btn--"]:hover {
    background: #822b3a;
    border-color: #822b3a; }
    .button:hover:after, .btn:hover:after, a[class^="btn--"]:hover:after {
      transform: translateX(0.25em); }
  .button:focus, .btn:focus, a[class^="btn--"]:focus {
    border: 2px solid #444;
    outline: none; }

a, a:visited {
  color: #323232;
  text-decoration: underline; }

.form-column .column-title {
  text-align: center;
  color: #4EB69F;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 50px; }
  .form-column .column-title:after {
    content: '';
    display: block;
    height: 5px;
    width: 100px;
    background: #4EB69F; }

.form-column .field-row {
  display: flex;
  flex-flow: column nowrap; }
  @media only screen and (min-width: 768px) {
    .form-column .field-row {
      flex-flow: row nowrap; } }
  .form-column .field-row .field-wrapper {
    flex: 1;
    box-sizing: border-box;
    padding-right: 15px;
    margin-bottom: 15px;
    position: relative; }
    .form-column .field-row .field-wrapper:last-of-type {
      padding-right: 0; }
    .form-column .field-row .field-wrapper label {
      box-sizing: border-box;
      display: block;
      width: 100%;
      font-size: .9rem;
      font-weight: bold;
      padding-bottom: 4px; }
    .form-column .field-row .field-wrapper input {
      box-sizing: border-box;
      display: block;
      width: 100%;
      padding: 5px;
      font-size: 1.2rem; }
    @media only screen and (min-width: 768px) {
      .form-column .field-row .field-wrapper {
        margin-bottom: 30px; } }

.form-column .field {
  border: 2px solid #f8f8f8;
  color: #444; }
  .form-column .field.validated-false {
    border-color: crimson; }
  .form-column .field.datepicker {
    background: url("../images/icons/calendar.png");
    background-position: 99% 50%;
    background-size: 1.3rem 1.3rem;
    background-repeat: no-repeat; }

.form-column .validation-error {
  color: crimson;
  font-size: .9rem;
  font-weight: bold; }

.form-column .payment-wrapper {
  background: #4EB69F;
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  width: calc( 100% + 60px);
  margin-left: -30px;
  margin-right: -30px; }
  .form-column .payment-wrapper p, .form-column .payment-wrapper strong, .form-column .payment-wrapper a, .form-column .payment-wrapper label {
    color: #fff; }
  .form-column .payment-wrapper select {
    width: 100%; }
  .form-column .payment-wrapper .price {
    display: block;
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold;
    margin: 15px 0 0 0; }
  .form-column .payment-wrapper .field-wrapper label {
    padding-bottom: 20px;
    display: block; }
  @media only screen and (min-width: 768px) {
    .form-column .payment-wrapper {
      flex-flow: row nowrap; }
      .form-column .payment-wrapper .field-wrapper {
        padding-left: 30px;
        margin-left: 30px;
        margin-bottom: 0;
        text-align: right;
        border-left: 1px solid #96d4c6; } }

.form-column .form-footer {
  padding-top: 30px;
  text-align: right; }
  .form-column .form-footer button {
    background: #D17585;
    color: #fff;
    border: 0;
    font-size: 1.3rem;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 1px 1px #5c1f29;
    border: 2px solid #D17585; }
    .form-column .form-footer button:hover {
      background: #75CED1;
      color: #444;
      box-shadow: 0 1px 1px #1f5a5c;
      border: 2px solid #75CED1; }

.ui-datepicker {
  background: #fff;
  z-index: 100 !important;
  width: 270px;
  padding: 0 15px; }
  .ui-datepicker table, .ui-datepicker .ui-datepicker-header {
    width: 100%; }
  .ui-datepicker table {
    padding: 15px; }
    .ui-datepicker table th, .ui-datepicker table td {
      padding: 7px 0; }
  .ui-datepicker .ui-datepicker-unselectable {
    opacity: .4; }
  .ui-datepicker .ui-datepicker-header {
    background: #4EB69F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    width: calc( 100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px; }
    .ui-datepicker .ui-datepicker-header span, .ui-datepicker .ui-datepicker-header a {
      color: #fff;
      cursor: pointer; }
    .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
      order: 3; }

.gallery-cell {
  width: 100%; }
  .gallery-cell img {
    width: 100%; }

.slider-column {
  position: relative; }
  .slider-column .flickity-viewport {
    overflow: hidden; }

.column.image img {
  width: 100%;
  height: auto;
  display: block; }

.section {
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  display: flex; }
  .section .column-wrapper {
    flex: 1 0 100%;
    display: flex;
    flex-flow: column nowrap;
    z-index: 10; }
    .section .column-wrapper .column {
      margin: 0 0 30px 0; }
    @media only screen and (min-width: 768px) {
      .section .column-wrapper {
        flex-flow: row nowrap; }
        .section .column-wrapper .column {
          flex: 1;
          margin-right: 50px; }
          .section .column-wrapper .column:last-of-type {
            margin-right: 0; } }

.section.intro {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  margin: -110px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 125px 0; }
  .section.intro .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../images/backdrop.jpg");
    background-position: center center;
    background-size: cover; }
    .section.intro .backdrop:after, .section.intro .backdrop:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 20vh;
      left: 0;
      right: 0;
      background: linear-gradient(transparent, rgba(78, 182, 159, 0.5)); }
    .section.intro .backdrop:before {
      bottom: auto;
      top: 0;
      background: linear-gradient(#2e7061, transparent);
      mix-blend-mode: darken; }
  .section.intro .column {
    z-index: 2;
    text-align: center; }
    .section.intro .column .column-title, .section.intro .column p {
      color: #fff;
      text-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }
    .section.intro .column .column-title {
      font-size: 3rem; }
    .section.intro .column p {
      font-size: 1.6rem;
      max-width: 75%;
      line-height: 160%;
      margin: 15px auto 50px auto; }
    .section.intro .column .button, .section.intro .column .btn, .section.intro .column a[class^="btn--"] {
      padding: 13px 43px 13px 28px;
      font-size: 1.3rem;
      color: #fff; }
      .section.intro .column .button:after, .section.intro .column .btn:after, .section.intro .column a[class^="btn--"]:after {
        top: .7em; }
  .section.intro + .section {
    margin-top: -50px; }
    @media only screen and (min-width: 1024px) {
      .section.intro + .section {
        margin-top: -100px; } }

@media only screen and (min-width: 1024px) {
  .detail.home-page .section.intro {
    min-height: 65vh; } }

.detail.home-page .section.intro .backdrop:before {
  background: linear-gradient(rgba(209, 117, 133, 0.5), transparent); }

.detail.home-page .section.intro .backdrop:after {
  background: linear-gradient(transparent, rgba(209, 117, 133, 0.5)); }

.section.cta {
  background: #f8f8f8;
  width: 100vw;
  max-width: initial;
  padding: 150px 0 50px 0;
  margin-top: -75px; }
  .section.cta .column-wrapper .column {
    background: none;
    box-shadow: none;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    background: #75CED1;
    padding-left: 30px;
    padding-right: 30px; }
    .section.cta .column-wrapper .column p {
      text-align: center;
      font-size: 1.4rem;
      line-height: 160%; }
      @media only screen and (min-width: 1024px) {
        .section.cta .column-wrapper .column p {
          padding-left: 100px;
          padding-right: 100px; } }

.block-column {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 15px; }
  .block-column img {
    width: 100%;
    height: auto; }
  .block-column .column-title {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center; }
    .block-column .column-title:after {
      margin-top: 10px;
      content: '';
      width: 50px;
      height: 10px;
      background: #D17585;
      display: block; }
  .block-column p {
    padding: 0 15px 15px 15px; }
  .block-column .button, .block-column .btn, .block-column a[class^="btn--"] {
    color: #fff;
    margin: auto auto 15px auto; }
  .block-column:nth-of-type(2) .column-title:after {
    background: #75CED1; }
  .block-column:nth-of-type(2) .button, .block-column:nth-of-type(2) .btn, .block-column:nth-of-type(2) a[class^="btn--"] {
    background: #75CED1;
    border-color: #75CED1;
    box-shadow: 0 1px 1px #1f5a5c;
    color: #1f5a5c; }
    .block-column:nth-of-type(2) .button:after, .block-column:nth-of-type(2) .btn:after, .block-column:nth-of-type(2) a[class^="btn--"]:after {
      background-image: url("../images/icons/icon-right.svg"); }
    .block-column:nth-of-type(2) .button:hover, .block-column:nth-of-type(2) .btn:hover, .block-column:nth-of-type(2) a[class^="btn--"]:hover {
      background: #38a4a8;
      border-color: #38a4a8; }
  .block-column:nth-of-type(3) .column-title:after {
    background: #4EB69F; }
  .block-column:nth-of-type(3) .button, .block-column:nth-of-type(3) .btn, .block-column:nth-of-type(3) a[class^="btn--"] {
    background: #4EB69F;
    border-color: #4EB69F;
    box-shadow: 0 1px 1px #102822; }
    .block-column:nth-of-type(3) .button:hover, .block-column:nth-of-type(3) .btn:hover, .block-column:nth-of-type(3) a[class^="btn--"]:hover {
      background: #2e7061;
      border-color: #2e7061; }

.collection {
  margin: 0 auto;
  display: block;
  width: 90%; }
  .collection .block-row {
    width: 100%;
    display: flex;
    flex-flow: column nowrap; }
    .collection .block-row .block {
      flex: 1 0 100%;
      margin: 0 0 30px 0; }
    @media only screen and (min-width: 768px) {
      .collection .block-row {
        flex-flow: row wrap; }
        .collection .block-row .block {
          flex: 1 0 45%;
          margin: 0 30px 30px 0; } }
  .collection .block {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    border-bottom: 6px solid #fff;
    text-decoration: none; }
    .collection .block h2, .collection .block p {
      padding-left: 15px;
      padding-right: 15px; }
    .collection .block h2 {
      margin-top: 15px; }
    .collection .block figure {
      padding: 0;
      margin: 0;
      position: relative; }
    .collection .block .price {
      position: absolute;
      bottom: 15px;
      right: 0;
      background: #fff;
      font-size: 1.8rem;
      color: #4EB69F;
      padding: 5px 10px;
      font-family: "Crete Round", Georgia; }
    .collection .block img {
      width: 100%;
      height: auto; }
    .collection .block:hover {
      border-color: #75CED1; }
      .collection .block:hover h2 {
        color: #75CED1; }

.arrangement .section:not(.intro) {
  background: #fff;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .arrangement .section:not(.intro) {
      width: 80%; } }
  @media only screen and (min-width: 1024px) {
    .arrangement .section:not(.intro) {
      width: 60%; } }
  .arrangement .section:not(.intro) .column-wrapper {
    box-sizing: border-box;
    background: #fff;
    padding: 30px 30px 15px 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); }
    .arrangement .section:not(.intro) .column-wrapper .column.content h2 {
      margin-top: 0;
      text-align: center;
      color: #4EB69F;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      line-height: 180%; }
      .arrangement .section:not(.intro) .column-wrapper .column.content h2:after {
        content: '';
        display: block;
        height: 5px;
        width: 100px;
        background: #4EB69F; }
    .arrangement .section:not(.intro) .column-wrapper.half-half .column.content {
      position: relative; }
      .arrangement .section:not(.intro) .column-wrapper.half-half .column.content .button, .arrangement .section:not(.intro) .column-wrapper.half-half .column.content .btn, .arrangement .section:not(.intro) .column-wrapper.half-half .column.content a[class^="btn--"] {
        margin-top: 15px;
        margin-bottom: 10px;
        float: right; }
  .arrangement .section:not(.intro):after {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 10px;
    background: #fff;
    display: block;
    content: '';
    z-index: 11; }
  .arrangement .section:not(.intro):last-of-type:after {
    display: none; }
  .arrangement .section:not(.intro) .column.content {
    font-size: 1.2rem;
    line-height: 170%;
    margin-bottom: 0; }
    .arrangement .section:not(.intro) .column.content p {
      margin: 0; }

.detail.contact .section:not(.intro), .detail.thanks .section:not(.intro) {
  background: #fff;
  position: relative;
  padding: 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); }

.detail.contact .column-title, .detail.thanks .column-title {
  text-align: center;
  color: #4EB69F;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  line-height: 180%;
  margin-top: 0; }
  .detail.contact .column-title:after, .detail.thanks .column-title:after {
    content: '';
    display: block;
    height: 5px;
    width: 100px;
    background: #4EB69F; }

.detail.contact .column.content p, .detail.thanks .column.content p {
  text-align: center; }

.detail.contact .field, .detail.thanks .field {
  padding: 7px 15px; }

.detail.contact textarea.field, .detail.thanks textarea.field {
  width: 100%;
  min-height: 200px;
  padding: 15px; }

.detail.thanks {
  min-height: calc( 100vh - 45px); }
