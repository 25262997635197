.section{
    max-width: $container;
    width: 90%;
    margin: 0 auto;
    display: flex;

    .column-wrapper{
        flex: 1 0 100%;
        display: flex;
        flex-flow: column nowrap;
        z-index: 10;

        .column{
            margin: 0 0 $gap * 2 0;
        }

        @media only screen and ( min-width: $tablet ){
            flex-flow: row nowrap;

            .column{
                flex: 1;
                margin-right: $gap-large;

                &:last-of-type{
                    margin-right: 0;
                }
            }
        }

        
    }
}